<template>
  <div id="newsStoryContent">
    <p id="Text1">
      <!-- pic8F59A4D7A3DC3E071CF316ED32F1E573.jpg -->
      <img class="rounded" style="height: 265; width: 440px;" alt="GamesCom fans" src="">
      <br>
      <br>
      After months of hard work, we were finally ready and very excited to show off the
      German version of LEGO® Universe at GamesCom, the world’s largest games event.
      <br>
      <br>
      <!-- pic3F80D8AD223330D3981531C6C0412F66.jpg -->
      <img class="rounded" style="height: 288px; width: 220px; margin-right: 8px; float: left;" alt="Doctor Overbuild" src="">
      “Spitzenklasse’’, ‘’Sehr Cool’’ and ‘’Viel Spass’’; the verdict was loud and clear from
      the tons of gamers, kids and parents who stopped by and played the game on the show floor.
      <br>
      Parent leaflets and beta key cards were handed out to a highly enthusiastic audience. The
      LEGO Minifigures were likewise a huge hit, and a great number of little plastic guys have
      now found new homes all over Germany.
      <br>
      <br>
      Also, the press showed great excitement and applauding comments such as “highlight of the
      how” and “the best MMO I’ve ever seen” flew through the press room during engaging demo
      session, which highlighted how creativity and safety have been mixed together to strengthen
      the way players can interact.
      <br>
      <br>
      Overall, GamesCom was a fantastic time, and as usual LEGO Universe spoke for itself in many
      ways, but the German members of the booth team upped it an extra notch. And our
      English-speaking staff proved that creative sign language is just as powerful as the spoken
      word.
      <br>
      <br>
      <!-- picDE62AD4642083D54D8AC007AA0AD74B1.jpg -->
      <img class="rounded" style="height: 265px; width: 440px;" alt="GamesCom fans" src="">
    </p>
  </div>
</template>
